import React, { Component, useEffect }  from 'react'
import ReactGA from 'react-ga'
import Img from 'gatsby-image'
import Layout from "../components/layout";
import programListData from '../../programListSummary.json';
import ProgramInfoCommon from "./program-listing-info-common-ug";
import Swiper, { Navigation, Pagination, Autoplay,  Mousewheel} from 'swiper';
import {  useStaticQuery, graphql } from "gatsby"
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';
import "../css/swiper-bundle.min.css";




const ProgramInfoCommonList = (node) => {
    // const node = this.props.data.allWordpressWpPogramlist.edges[0];
     //console.log("GraphQL Query Data:"+JSON.stringify(node) ); 
    return (
        <ProgramInfoCommon
            DetailPageUrl={node.acf.third_party_link}
            Target={"_self"}
            UniversityImage={node.featured_media.source_url}
            UniversityRank={node.acf.university_ranking}
            UniversityLogo={node.acf.university_logo.source_url}
            ProgramName={node.title}
            PogramDuration={node.acf.program_dudation}
            Country={node.acf.country.trim()}
            UniversityName={node.acf.list_of_university}
            ShortEligibility={node.acf.short_eligibility}
            YouSave={node.acf.you_save}
            Term1Info={node.acf.term_1}
            Term2Info={node.acf.term_2}
            Term3Info={node.acf.year_2_and_3}
            Term1Title={node.acf.term_1_title}
            Term2Title={node.acf.term_2_title}
            Term3Title={node.acf.term_3_title}
            isFeatured={node.acf.featured}
            featuredOrder={node.acf.featured_order}
            wordpress_id={node.wordpress_id}
         />
            
    );
};





const _ = require(`lodash`);

const PopularDegreeOutcome = (props) => {
  
  

  const { data, degrees, programLink, popularDegrees  } = props; 

  useEffect(() => {
    var swiper = new Swiper('.degree-slide', {
      centeredSlides: false,
      loop: false,
      slidesPerView: 1,
      watchOverflow: true,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination-degree',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next-degree',
        prevEl: '.swiper-prev-degree',
      },
      breakpoints: {
        990: { slidesPerGroup: 3, slidesPerView: 3 },
        768: { slidesPerGroup: 2, slidesPerView: 2 },
        576: { slidesPerGroup: 1, slidesPerView: 1 }
      }
    });
  }, []); // Empty dependency array ensures this runs after component mount


  //const program_data = programListData.programlist;
  const program_data = popularDegrees;


const programList = [] ;

for (let i = 0; i < program_data.length; i++) {
  const node = program_data[i].node;


    if(node.acf.degree_type == "Undergraduate" ) {
     
   
        node.acf.third_party_link = `/program-detail/${node.acf.country.replace(/\s+/g, "-").toLowerCase()}/${_.kebabCase(node.acf.name_of_university)}/ug/${_.kebabCase(node.slug)}/`
        
  
    } else {
    
        node.acf.third_party_link = `/program-detail/${node.acf.country.replace(/\s+/g, "-").toLowerCase()}/${_.kebabCase(node.acf.name_of_university)}/pg/${_.kebabCase(node.slug)}/`
      
    }
    if(programLink == "top" ) {
      node.acf.third_party_link = "#top"; 
    }
  
    programList.push(node);

}


      

  return (
    <div class=" col-12 justify-content-center  ">
           <div class="degree-slide swiper-container swiper-style-new-design">
<div class="swiper-wrapper">
					
{
													programList &&
													programList.length > 0 &&
													programList.map((node, index) => {
														
														
														// console.log("Id", node.Country, node.GraduationlistId,this.state.sortValue1);
														//  if ( node.Country === this.state.sortValue || this.state.sortValue === null || this.state.sortValue === "NULL" || this.state.sortValue1 === node.GraduationlistId ||  this.state.sortValue1 === null ||
														//    this.state.sortValue1 === "NULL")
														//   {
														//console.log("node-->" + JSON.stringify(node.title));
														
														return (
															<React.Fragment key={node.id}>
																<div class="swiper-slide ">
															<div  className="col-sm-12 col-12 col-lg-12 program-box-wrapper margin-30px-bottom padding-25px-lr" >
																{ProgramInfoCommonList(node) }
															</div>
															</div>
															</React.Fragment>
														);
														
														// }
													})
												}

    </div>
    <div className="swiper-pagination swiper-pagination-degree"></div>
    <div class="swiper-button-next swiper-next-degree rounded-circle light slider-navigation-style-07 box-shadow-double-large"><img src={ArrowRightSolidIcon} alt="->" className="left-icon newicon newicon-black left2pxtop0px" /></div>
	<div class="swiper-button-prev swiper-prev-degree rounded-circle light slider-navigation-style-07 box-shadow-double-large"><img src={ArrowLeftSolidIcon} alt="<-" className="left-icon newicon newicon-black left2pxtop0px" /></div>
		
    </div>
    </div>
  )
}




export default PopularDegreeOutcome
